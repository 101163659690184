<template>
  <div class="admin-home-page">
    <b-card :title="title">
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <vue-good-table
          :columns="table.fields"
          :rows="table.rows"
          :fixed-header="true"
          @on-cell-click="onCellClick"
        >
          <template
            slot="table-row"
            slot-scope="props"
            class="p-0"
          >
            <span
              v-if="props.column.field === 'T0'"
              :class="props.row.T0 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T1'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T1 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T2'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T2 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T3'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T3 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T4'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T4 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T5'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T5 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T6'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T6 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T7'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T7 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T8'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T8 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T9'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T9 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T10'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T10 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T11'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T11 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T12'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T12 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="props.column.field === 'T13'"
              v-b-tooltip.hover.top="showTitle(props.row)"
              :class="props.row.T13 == 'X' ?'my-class':''"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-overlay>
    </b-card>
    <b-modal
      id="modal-info"
      ref="modal-info"
      ok-only
      centered
      size="lg"
      title="Thông tin tiết học"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <div>
        <p>Môn học: {{ dataModal.subject }}</p>
        <p>Giảng viên: {{ dataModal.teacher }}</p>
        <p>Phòng học: {{ dataModal.room }}</p>
      </div>
    </b-modal>
  </div>

</template>

<script>
import {
  VBTooltip, BCard, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import moment from 'moment'

export default {
  components: {
    BCard,
    VueGoodTable,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [],
        rows: [],
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: '',
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        creditClassId: '',
        departmentId: '',
        studentId: '',
        teacherId: '',
        roomId: '',
        dayId: '',
        subjectId: '',
      },
      dataSourceView: [],
      subjectIds: [],
      user: getUser(),
      dataModal: {
        teacher: '',
        room: '',
        turns: '',
        subject: '',
      },
      currentDate: '',
      weekday: '',
      title: '',
    }
  },

  async created() {
    // init data FROM API
    this.currentDate = this.format_date(new Date())
    this.weekday = moment().weekday()
    this.title = `Lịch giảng dạy toàn trường ngày hôm nay ${this.currentDate}`
    this.isLoading = true
    await Promise.all([
      this.getTurns(),
      this.getDays(),
      // this.getSubjects({currentPage: 1, itemsPerPage: 1000})
    ])
    this.turns.unshift({
      name: 'Môn học',
      code: 'T0',
    })
    if (this.turns.length) {
      this.table.fields = this.turns.map((item, index) => ({
        label: item.name,
        field: item.code,
        sortable: false,
        // eslint-disable-next-line eqeqeq
        tdClass: index == 0 ? 'text-left custom-th-class' : 'text-center',
        // eslint-disable-next-line eqeqeq
        thClass: index == 0 ? 'text-left custom-th-class' : 'text-center',
      }))
    }
    await this.onSearchMainData()
    this.isLoading = false
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      schedulers: 'schedule/schedulers',
      turns: 'turn/turns',
      days: 'day/days',
      subjects: 'subject/subjects',
    }),
    courseId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.courseId : null
    },
  },
  watch: {
    // listening change data init

  },
  methods: {
    // handle even
    ...mapActions({
      getTurns: 'turn/getTurns',
      getSchedulers: 'schedule/getSchedulersV3',
      getDays: 'day/getDays',
      getSubjects: 'subject/readSubjects',
    }),

    async onSearchMainData() {
      this.filter.organizationId = this.user.orgId
      this.filter.courseId = this.courseId
      this.filter.dayId = this.weekday > 0 ? this.weekday : 7
      this.table.dataRows = []
      await this.getSchedulers(this.filter)
      try {
        if (this.schedulers.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.schedulers.length; j++) {
            const infoScheduler = this.schedulers[j]
            let turnArr = ''
            if (infoScheduler && infoScheduler.groupTurns) {
              const { groupTurns } = infoScheduler
              const groupTurnsArr = groupTurns.split(',')
              turnArr = this.convertObjectToArray(groupTurnsArr)
            }

            const item = {
              T0: `${infoScheduler.subjectName}(${infoScheduler.creditClassCode})`,
              T1: '',
              T2: '',
              T3: '',
              T4: '',
              T5: '',
              T6: '',
              T7: '',
              T8: '',
              T9: '',
              T10: '',
              T11: '',
              T12: '',
              T13: '',
              T14: '',
              teacher: infoScheduler ? infoScheduler.teacherName : '',
              subject: infoScheduler ? infoScheduler.subjectName : '',
              room: infoScheduler ? infoScheduler.roomName : '',
            }
            const itemKq = { ...item, ...turnArr }
            this.table.rows.push(itemKq)
          }
        }
      } catch (e) {
      }
    },

    async onCellClick(e) {
      this.dataModal = {}
      const { column } = e
      const { field } = column
      const dataRow = e.row
      this.dataModal.subject = dataRow.T0
      this.dataModal.teacher = dataRow.teacher
      this.dataModal.room = dataRow.room
      if (dataRow[field]) {
        this.$bvModal.show('modal-info')
      }
    },
    showTitle(data) {
      return `${data.room} - ${data.teacher}`
    },
    convertObjectToArray(arr, type = 'X') {
      const obj = arr.reduce((accumulator, value) => ({ ...accumulator, [value]: type }), {})
      return obj
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/yyyy')
      }
      return 'DD/MM/yyyy'
    },

    // onCellClick(dataRow) {
    //   // this.dataModal.subject = dataRow.T0
    //   // this.dataModal.teacher = dataRow.teacher
    //   // this.dataModal.room = dataRow.room
    //   // this.$bvModal.show('modal-info')
    // }

  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .my-class {
    color: #28c76f !important;
    display: block;
    width: 100%;
    height: 100%;
  }

  tbody {
    overflow-x: hidden !important;
  }

  .card-body .vgt-wrap {
    overflow-x: scroll;
    .vgt-responsive {
      overflow-x: hidden;
    }
  }

  .custom-th-class {
    min-width: 220px !important;
    word-break: break-word;
  }

  .vgt-inner-wrap .vgt-responsive {
    position: absolute;
    display: contents;
  }

  .card-body .vgt-wrap {
    position: relative
  }
}
</style>
